import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import SEO from '@/helpers/SEOHelper.js'

import Layout from '@/components/Layout'
import Form from '@/components/Forms/Contact'
import Map from '@/components/Map'

class ContactPage extends React.Component {
  render() {
    const { data } = this.props
    const pageData = data.markdownRemark.frontmatter
    const seo = pageData.seo
    return (
      <Layout>
        <Helmet>
          <meta name='description' content={SEO.description(seo.description)} />
          <meta property='og:title' content={SEO.title(seo.title)} />
          <meta
            property='og:description'
            content={SEO.description(seo.description)}
          />
          <meta property='og:image' content={SEO.image(seo.image)} />
          <title>{SEO.title(seo.title)}</title>
        </Helmet>
        <div className='top-bg top-bg-contact bg-grey-lightest' />
        <div className='container-lg pt-56 lg:pt-20'>
          <div className='contact'>
            <div className='contact__content'>
              <h2 className='uppercase text-sm'>{pageData.hero.label}</h2>
              <h1 className='text-h3 sm:text-h1 mb-6'>
                {pageData.hero.heading}
              </h1>
              <p className='text-h5'>{pageData.hero.para}</p>
            </div>
            <div className='contact-box'>
              <div className='flex flex-col justify-between'>
                <div className='contact-item'>
                  <div className='contact-item__title'>
                    {pageData.adress.label1}
                  </div>
                  <div className='contact-item__content'>
                    {pageData.adress.adress1}
                  </div>
                  <div className='contact-item__content'>
                    {pageData.adress.adress2}
                  </div>
                </div>

                <div className='contact-item'>
                  <div className='contact-item__title'>
                    {pageData.adress.label2}
                  </div>
                  <div className='contact-item__content'>
                    <a href={`mailto:${pageData.adress.email}`}>
                      {pageData.adress.email}
                    </a>
                  </div>
                </div>

                <div className='contact-item'>
                  <div className='contact-item__title'>
                    {pageData.adress.label3}
                  </div>
                  <div className='contact-item__content'>
                    <a href={`tel:${pageData.adress.phone}`}>
                      {pageData.adress.phone}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container-lg py-24 md:py-32'>
          <Form formTitle='Contact Form' />
        </div>

        <Map
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyALWhNZ4Pjlhqb2B831kc7m-g9D5kdaLRM&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div className='contact__map' />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </Layout>
    )
  }
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "contact" } }) {
      frontmatter {
        hero {
          heading
          label
          para
          buttonText
        }
        adress {
          label1
          adress1
          adress2
          label2
          email
          label3
          phone
        }
        seo {
          title
          description
        }
      }
    }
  }
`
